/* ======================== media query ======================== */
@media screen and (min-device-width: 1000px) and (max-device-width: 1460px) {
  .dashboard-page .dashboard-body .data-show-left-block .cart-block {
    padding: 15px;
  }
  .dashboard-page .dashboard-body .data-show-left-block .data-block {
    grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  }
  .dashboard-page .dashboard-body .chart-show-right-block .chart-show {
    max-width: 426px;
    max-height: 362px;
  }
}

@media screen and (max-device-width: 1200px) {
  .main-menu {
    display: none;
  }

  .mobile-info-menu {
    display: flex !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1000px) {
  .my-dashboard {
    flex-direction: column;
  }
  .dashboard-page .dashboard-body .data-show-left-block .cart-block {
    padding: 15px;
  }
  .dashboard-page .dashboard-body .data-show-left-block .data-block {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }
  .dashboard-page
    .dashboard-body
    .data-show-left-block
    .cart-block
    .group-data
    h3 {
    font-size: 22px;
  }
  .dashboard-page .dashboard-body .data-show-left-block .cart-block .cd-p {
    font-size: 12px;
  }
  .dashboard-page .dashboard-body .chart-show-right-block .chart-show {
    max-width: 500px;
    max-height: 500px;
    margin: 0px auto;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 920px) {
  .dashboard-page .dashboard-body .data-show-left-block .data-block {
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  }
  .dashboard-page
    .dashboard-body
    .data-show-left-block
    .cart-block
    .icon-block
    .icons {
    font-size: 45px;
    padding: 11px;
  }
  .dashboard-page
    .dashboard-body
    .data-show-left-block
    .cart-block
    .group-data
    h3 {
    font-size: 15px;
  }
  .dashboard-page .dashboard-body .data-show-left-block .cart-block .cd-p {
    font-size: 12px;
  }
  .dashboard-page .dashboard-body .chart-show-right-block .chart-show {
    max-width: 500px;
    max-height: 500px;
    margin: 0px auto;
  }
}

@media screen and (max-device-width: 768px) {
  .dashboard-page .dashboard-body .data-show-left-block .data-block {
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  }
  .dashboard-page
    .dashboard-body
    .data-show-left-block
    .cart-block
    .icon-block
    .icons {
    font-size: 45px;
    padding: 11px;
  }
  .dashboard-page
    .dashboard-body
    .data-show-left-block
    .cart-block
    .group-data
    h3 {
    font-size: 15px;
  }
  .dashboard-page .dashboard-body .data-show-left-block .cart-block .cd-p {
    font-size: 12px;
  }
  .dashboard-page .dashboard-body .chart-show-right-block .chart-show {
    max-width: 500px;
    max-height: 500px;
    margin: 0px auto;
  }
}

@media screen and (max-device-width: 518px) {
  .dashboard-page .dashboard-body .data-show-left-block .data-block {
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  }
  .dashboard-page
    .dashboard-body
    .data-show-left-block
    .cart-block
    .icon-block
    .icons {
    font-size: 60px;
    padding: 15px;
  }
  .dashboard-page
    .dashboard-body
    .data-show-left-block
    .cart-block
    .group-data
    h3 {
    font-size: 22px;
  }
  .dashboard-page .dashboard-body .data-show-left-block .cart-block .cd-p {
    font-size: 14px;
  }

  .inner-container {
    padding: 0px 20px !important;
  }
}
