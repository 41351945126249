.back-btn-per-page {
  border: 0px;
  outline: 0px;
  background: #ffffff;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.back-btn-per-page .back-icon-per-page {
  font-size: 14px;
}

/* ========================= navbar ========================== */
.navbar {
  height: 72px;
}

.navbar .navbar-brand {
  display: flex;
  align-items: center;
  gap: 121px;
}

.navbar .navbar-brand .navbar-btn {
  /* border: 2px solid rgb(0, 0, 0); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: transparent;
}

/* .offcanvas .offcanvas-header .btn-close:focus {
  box-shadow: none;
}

.offcanvas-body ul li{
  transition: color 0.3s ease;
}

.offcanvas-body ul li:hover {
  color: #007bff;
}

.offcanvas-body ul li.active .nav-link {
  font-weight: bold;
  color: #007bff;
}

.offcanvas-body ul li button.nav-link:hover {
  color: #007bff;
}

.offcanvas-body ul li button.nav-link:active {
  font-weight: bold;
  color: #007bff;
} */

/* ========================= footer ========================== */
footer {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ========================= main section ========================== */
.main-section {
  min-height: calc(100vh - 146px);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 0.7511379551820728) 0%,
    rgba(148, 187, 233, 0.7539390756302521) 100%
  );
  padding: 30px 0px;
}

/* ========================= product ========================== */
.product-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.product-page .product-page-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-page .product-heading {
  gap: 14px;
}

.product-page .product-page-heading form {
  max-width: 300px;
  width: 100%;
}

.product-page .product-page-heading .search-box-input input {
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  padding: 8px;
}

.product-page .product-page-heading-add-link {
  border: 1px solid black;
  padding: 5px 20px;
  color: black;
  background: white;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  height: 38px;
}
.product-page .product-page-heading-add-link .plus-icon-add {
  font-size: 12px;
}

.product-page .product-page-body {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-page .product-page-body .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.product-page .product-page-body .table th,
.product-page .product-page-body .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.product-page .product-page-body .table th {
  background-color: #f2f2f2;
}

.product-page .product-page-body .table td img {
  max-width: 33px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.product-page .product-page-body .table td .action-icon-edit-link {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
}

.product-page .product-page-body .table td .action-icon-delete-btn {
  font-size: 16px;
  text-decoration: none;
  color: #e00000;
  border: 0px;
  outline: 0px;
  background: none;
  padding: 0px;
  line-height: 0px;
  margin-left: 10px;
}

.product-page .product-page-body .pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.product-page .product-page-body .pagination-controls label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-page .product-page-body .pagination-controls select {
  margin: 0px;
  padding: 4px;
}

.product-page .product-page-body .pagination-controls select:focus-visible {
  outline: none;
}

.product-page .product-page-body .pagination-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.product-page .product-page-body .pagination-buttons .pagination-btn {
  padding: 6px 20px;
  outline: none;
  height: 35px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #000000;
  color: #ffffff;
  background-color: #000000;
  transition: all 0.3s ease-in;
}

.product-page .product-page-body .pagination-buttons .pagination-btn:hover {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.product-page .product-page-body .pagination-buttons .pagination-btn:disabled {
  background-color: #dddddd;
  border: 1px solid #888888;
  color: #888888;
  cursor: not-allowed;
}

/* ========================= add product ========================== */
.add-product-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.add-product-page .inner-container .back-btn-per-page {
  border: 0px;
  outline: 0px;
  background: #ffffff;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.add-product-page .inner-container .add-product-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.add-product-body {
  padding: 30px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.add-product-body button {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.form-control:focus,
.form-check-input:focus {
  border-color: #dee2e6;
  outline: 0;
  box-shadow: none;
}
.form-check-input {
  margin-top: 6px;
  font-size: 14px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
  /* background-color: red; */
  border-radius: 30px;
  border: 2px solid gray;
}

.toggle:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  top: 1px;
  left: 1px;
  transition: all 0.5s;
}

.checkbox:checked + .toggle::after {
  left: 10px;
}

.checkbox:checked + .toggle {
  background-color: green;
}

.checkbox {
  display: none;
}

.checkbox-customize {
  width: 26px;
  height: 16px;
  border: 2px solid gray;
}

.only-images-name {
  display: flex;
  align-items: center;
  gap: 25px;
}
.only-images-name .uploaded-image {
  display: inline;
  position: relative;
}
.only-images-name .uploaded-image img {
  max-width: 70px;
  width: 100%;
  display: inline-block;
}
.only-images-name .uploaded-image .img-cross-icon {
  position: absolute;
  cursor: pointer;
  top: 1px;
  right: 1px;
  background: #000000;
  color: #ffffff;
  padding: 2px;
  border-radius: 50%;
}

/* ========================= edit product ========================== */
.edit-product-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.edit-product-page .inner-container .edit-product-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.edit-product-body {
  padding: 30px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.edit-product-page .edit-btn {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

/* ========================= b2b ========================== */
.b2b-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.b2b-page .b2b-page-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.b2b-page .b2b-heading {
  gap: 14px;
}

.b2b-page .b2b-page-heading form {
  max-width: 300px;
  width: 100%;
}

.b2b-page .b2b-page-heading .search-box-input input {
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  padding: 8px;
}

.b2b-page .b2b-page-heading-add-link {
  border: 1px solid black;
  padding: 5px 20px;
  color: black;
  background: white;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  height: 38px;
}
.b2b-page .b2b-page-heading-add-link .plus-icon-add {
  font-size: 12px;
}

.b2b-page .b2b-page-body {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.b2b-page .b2b-page-body .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.b2b-page .b2b-page-body .table th,
.b2b-page .b2b-page-body .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.b2b-page .b2b-page-body .table th {
  background-color: #f2f2f2;
}

.b2b-page .b2b-page-body .table td img {
  max-width: 33px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.b2b-page .b2b-page-body .table td .action-icon-edit-link {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
}

.b2b-page .b2b-page-body .table td .action-icon-delete-btn {
  font-size: 16px;
  text-decoration: none;
  color: #e00000;
  border: 0px;
  outline: 0px;
  background: none;
  padding: 0px;
  line-height: 0px;
  margin-left: 10px;
}

.b2b-page .b2b-page-body .pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.b2b-page .b2b-page-body .pagination-controls label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.b2b-page .b2b-page-body .pagination-controls select {
  margin: 0px;
  padding: 4px;
}

.b2b-page .b2b-page-body .pagination-controls select:focus-visible {
  outline: none;
}

.b2b-page .b2b-page-body .pagination-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.b2b-page .b2b-page-body .pagination-buttons .pagination-btn {
  padding: 6px 20px;
  outline: none;
  height: 35px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #000000;
  color: #ffffff;
  background-color: #000000;
  transition: all 0.3s ease-in;
}

.b2b-page .b2b-page-body .pagination-buttons .pagination-btn:hover {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.b2b-page .b2b-page-body .pagination-buttons .pagination-btn:disabled {
  background-color: #dddddd;
  border: 1px solid #888888;
  color: #888888;
  cursor: not-allowed;
}

/* ========================= add product ========================== */
.add-b2b-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.add-b2b-page .inner-container .back-btn-per-page {
  border: 0px;
  outline: 0px;
  background: #ffffff;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.add-b2b-page .inner-container .add-b2b-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.add-b2b-body {
  padding: 30px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.add-b2b-body button {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.form-control:focus,
.form-check-input:focus {
  border-color: #dee2e6;
  outline: 0;
  box-shadow: none;
}
.form-check-input {
  margin-top: 6px;
  font-size: 14px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
  /* background-color: red; */
  border-radius: 30px;
  border: 2px solid gray;
}

.toggle:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  top: 1px;
  left: 1px;
  transition: all 0.5s;
}

.checkbox:checked + .toggle::after {
  left: 10px;
}

.checkbox:checked + .toggle {
  background-color: green;
}

.checkbox {
  display: none;
}

.only-images-name {
  display: flex;
  align-items: center;
  gap: 25px;
}
.only-images-name .uploaded-image {
  display: inline;
  position: relative;
}
.only-images-name .uploaded-image img {
  max-width: 70px;
  width: 100%;
  display: inline-block;
}
.only-images-name .uploaded-image .img-cross-icon {
  position: absolute;
  cursor: pointer;
  top: 1px;
  right: 1px;
  background: #000000;
  color: #ffffff;
  padding: 2px;
  border-radius: 50%;
}

/* ========================= add product ========================== */
.edit-b2b-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.edit-b2b-page .inner-container .back-btn-per-page {
  border: 0px;
  outline: 0px;
  background: #ffffff;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.edit-b2b-page .inner-container .edit-b2b-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.edit-b2b-body {
  padding: 30px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.edit-b2b-body button {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.form-control:focus,
.form-check-input:focus {
  border-color: #dee2e6;
  outline: 0;
  box-shadow: none;
}
.form-check-input {
  margin-top: 6px;
  font-size: 14px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
  /* background-color: red; */
  border-radius: 30px;
  border: 2px solid gray;
}

.toggle:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  top: 1px;
  left: 1px;
  transition: all 0.5s;
}

.checkbox:checked + .toggle::after {
  left: 10px;
}

.checkbox:checked + .toggle {
  background-color: green;
}

.checkbox {
  display: none;
}

.only-images-name {
  display: flex;
  align-items: center;
  gap: 25px;
}
.only-images-name .uploaded-image {
  display: inline;
  position: relative;
}
.only-images-name .uploaded-image img {
  max-width: 70px;
  width: 100%;
  display: inline-block;
}
.only-images-name .uploaded-image .img-cross-icon {
  position: absolute;
  cursor: pointer;
  top: 1px;
  right: 1px;
  background: #000000;
  color: #ffffff;
  padding: 2px;
  border-radius: 50%;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: none;
}

/* ========================= sign up ========================== */
.sign-up-page .our-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.sign-up-page .inner-container {
  max-width: 600px;
  width: 100%;
  padding: 0px 50px;
}

.sign-up-page .sign-up-page-body {
  padding: 20px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.sign-up-page .sign-up-page-body .sign-up-page-form {
  width: 100%;
}

.sign-up-page form .sign-up-form-group label {
  font-size: 14px;
  color: #000000;
}

.sign-up-page form .sign-up-form-group input {
  font-size: 14px;
  height: 45px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
}

.sign-up-page form .sign-up-email-group input {
  font-size: 14px;
  height: 45px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
}

.sign-up-page form .sign-up-email-group .email-toggle-icon {
  height: 45px;
  border: 0;
  outline: 0;
  font-size: 14px;
  background: transparent;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
}

.sign-up-page form .sign-up-password-group input {
  font-size: 14px;
  height: 45px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
}

.sign-up-page form .sign-up-password-group .password-toggle-icon {
  height: 45px;
  border: 0;
  outline: 0;
  font-size: 14px;
  background: transparent;
  padding: 10px;
  cursor: pointer;
}

.sign-up-page form .sign-up-form-button {
  outline: none;
  width: 100%;
  height: 45px;
  font-size: 14px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.sign-up-page form .sign-up-register-para {
  color: #888888;
}

.sign-up-page form .sign-up-register-para .sign-up-register-link {
  color: #000000;
}

/* ========================= sign in ========================== */
.sign-in-page .our-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.sign-in-page .inner-container {
  max-width: 600px;
  width: 100%;
  padding: 0px 50px;
}

.sign-in-page .sign-in-page-body {
  padding: 20px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.sign-in-page .sign-in-page-form {
  width: 100%;
}

.sign-in-page form .sign-in-form-group label {
  font-size: 14px;
  color: #000000;
}

.sign-in-page form .sign-in-form-group input {
  font-size: 14px;
  height: 45px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
}

.sign-in-page form .sign-in-form-group .sign-in-password-group input {
  font-size: 14px;
  height: 45px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
}

.sign-in-page
  form
  .sign-in-form-group
  .sign-in-password-group
  .password-toggle-icon {
  height: 45px;
  border: 0;
  outline: 0;
  font-size: 14px;
  background: transparent;
  padding: 10px;
  cursor: pointer;
}

.sign-in-page form .sign-in-form-button {
  outline: none;
  width: 100%;
  height: 45px;
  font-size: 14px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.sign-in-page form .sign-in-register-para {
  color: #888888;
}

.sign-in-page form .sign-in-register-para .sign-in-register-link {
  color: #000000;
}

/* ========================= forgot password ========================== */
.forgot-password-page .our-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.forgot-password-page .inner-container {
  max-width: 600px;
  width: 100%;
  padding: 0px 50px;
}

.forgot-password-page .forgot-password-page-body {
  padding: 20px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.forgot-password-page .forgot-password-page-form {
  width: 100%;
}

.forgot-password-page form .forgot-password-form-group label {
  font-size: 14px;
  color: #000000;
}

.forgot-password-page form .forgot-password-form-group input {
  font-size: 14px;
  height: 45px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
}

.forgot-password-page
  form
  .forgot-password-form-group
  .forgot-password-password-group
  input {
  font-size: 14px;
  height: 45px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
}

.forgot-password-page
  form
  .forgot-password-form-group
  .forgot-password-password-group
  .password-toggle-icon {
  height: 45px;
  border: 0;
  outline: 0;
  font-size: 14px;
  background: transparent;
  padding: 10px;
  cursor: pointer;
}

.forgot-password-page form .forgot-password-form-button {
  outline: none;
  width: 100%;
  height: 45px;
  font-size: 14px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.forgot-password-page form .forgot-password-register-para {
  color: #888888;
}

.forgot-password-page
  form
  .forgot-password-register-para
  .forgot-password-register-link {
  color: #000000;
}

/* ========================= dashboard ========================== */
.dashboard-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.dashboard-page .dashboard-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dashboard-page .dashboard-heading select {
  padding: 10px;
  font-size: 16px;
}

.dashboard-page .dashboard-heading select:focus-visible {
  outline: 0px solid rgb(118, 118, 118);
}

.dashboard-page .dashboard-body .data-show-left-block {
  flex: 1;
}

.dashboard-page .dashboard-body .data-show-left-block .data-block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
}

.dashboard-page .dashboard-body .data-show-left-block .cart-block {
  padding: 30px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dashboard-page .dashboard-body .data-show-left-block .cart-block .icon-block {
  text-align: center;
  margin-bottom: 14px;
}

.dashboard-page
  .dashboard-body
  .data-show-left-block
  .cart-block
  .icon-block
  .icons {
  font-size: 60px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px;
  border-radius: 30%;
}

.dashboard-page .dashboard-body .data-show-left-block .cart-block .group-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-page
  .dashboard-body
  .data-show-left-block
  .cart-block
  .group-data
  .gd-p {
  margin: 0px;
  font-weight: 600;
}

.dashboard-page .dashboard-body .data-show-left-block .cart-block .cd-p {
  margin: 0px;
  display: inline;
  padding: 5px 9px;
  background: #959595;
  color: #ffffff;
  border-radius: 25px;
}

.dashboard-page .dashboard-body .chart-show-right-block {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dashboard-page .dashboard-body .chart-show-right-block .chart-show {
  max-width: 426px;
  width: 100%;
  height: 424px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ========================= order ========================== */
.order-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.order-page .inner-container .order-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-page .inner-container .order-heading > .total-sale {
  font-size: 1.5rem;
  color: var(--secondary-color);
  text-align: end;
  margin: 0;
}

/* .order-page .order-page-heading {
  gap: 14px;
} */

.product-orders-filter-block-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.product-orders-filter-block-row > input {
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  color: var(--secondary-color);
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 48px;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  max-width: 500px;
}

.product-orders-filter-block-row > select {
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  color: var(--secondary-color);
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 48px;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  max-width: 500px;
}

.product-orders-filter-block-row > .start-date-block {
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 48px;
  border-radius: 4px;
  width: 100%;
}

.product-orders-filter-block-row > .start-date-block .react-date-picker {
  height: 100%;
  width: 100%;
}

.product-orders-filter-block-row
  > .start-date-block
  .react-date-picker
  .react-date-picker__wrapper {
  border: unset;
}

.product-orders-filter-block-row > .end-date-block {
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 48px;
  border-radius: 4px;
  width: 100%;
}

.product-orders-filter-block-row > .end-date-block .react-date-picker {
  height: 100%;
  width: 100%;
}

.product-orders-filter-block-row
  > .end-date-block
  .react-date-picker
  .react-date-picker__wrapper {
  border: unset;
}

.orders-main-block .orders-body {
}

.order-edi-btn,
.order-view-btn {
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0px;
  line-height: 0px;
  border: none;
  outline: none;
  background-color: unset;
}

.order-view-svg-btn {
  color: green;
  /* margin-left: 15px; */
}

.order-page .order-page-heading form {
  max-width: 800px;
  width: 100%;
  margin: 0px auto;
  padding: 20px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* .order-page .order-page-heading .search-box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
}

.order-page .order-page-heading .search-box-input input {
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  padding: 8px;
}

.order-page .order-page-heading .search-box-input select {
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  padding: 8px;
} */

.order-page .order-page-heading .grid-block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.order-page .order-page-heading .grid-block .grid-b-item input {
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  padding: 8px;
}

.order-page .order-page-heading .grid-block .grid-b-item select {
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  padding: 8px;
}

.order-page .order-page-heading .order-page-btn {
  border: 1px solid rgb(0, 0, 0);
  padding: 5px 20px;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  text-decoration: none;
  font-weight: 600;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 100%;
  line-height: 0px;
  transition: all 0.3s ease-in-out;
}

.order-page .order-page-heading .order-page-btn:hover {
  color: #ffffff;
  background-color: #0b5ed7;
  border: 1px solid #ffffff;
}

/* .order-page .order-page-heading .search-box-input .order-page-btn {
  border: 1px solid rgb(0, 0, 0);
  padding: 5px 20px;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 100%;
  line-height: 0px;
  transition: all 0.3s ease-in-out;
}

.order-page .order-page-heading .search-box-input .order-page-btn:hover {
  color: #ffffff;
  background-color: #0b5ed7;
  border: 1px solid #ffffff;
} */

.order-page .order-page-body {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.order-page .order-page-body .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.order-page .order-page-body .table th,
.order-page .order-page-body .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.order-page .order-page-body .table th {
  background-color: #f2f2f2;
}

.order-page .order-page-body .table td img {
  max-width: 50px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.order-page .order-page-body .table td .edit-order-btn {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
  border: none;
  outline: none;
  background-color: transparent;
}

.order-page .order-page-body .table td .view-order-btn {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
  border: none;
  outline: none;
  background-color: transparent;
}

.order-page .order-page-body .table td .action-icon-edit-link {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
}

.order-page .order-page-body .table td .action-icon-delete-btn {
  font-size: 16px;
  text-decoration: none;
  color: #e00000;
  border: 0px;
  outline: 0px;
  background: none;
  padding: 0px;
  line-height: 0px;
  margin-left: 10px;
}

.order-page .order-page-body .pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.order-page .order-page-body .pagination-controls label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.order-page .order-page-body .pagination-controls select {
  margin: 0px;
  padding: 4px;
}

.order-page .order-page-body .pagination-controls select:focus-visible {
  outline: none;
}

.order-page .order-page-body .pagination-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.order-page .order-page-body .pagination-buttons .pagination-btn {
  padding: 6px 20px;
  outline: none;
  height: 35px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #000000;
  color: #ffffff;
  background-color: #000000;
  transition: all 0.3s ease-in;
}

.order-page .order-page-body .pagination-buttons .pagination-btn:hover {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.order-page .order-page-body .pagination-buttons .pagination-btn:disabled {
  background-color: #dddddd;
  border: 1px solid #888888;
  color: #888888;
  cursor: not-allowed;
}

#editOrderModal {
}

#editOrderModal .edit-order-body {
}

#editOrderModal .edit-order-body form {
}

#editOrderModal .edit-order-body form .contact-form-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

#editOrderModal .edit-order-body form .contact-form-group label {
  font-size: 14px;
  margin-bottom: 4px;
  color: #000000;
  text-transform: capitalize;
  font-weight: 600;
}

#editOrderModal .edit-order-body form .contact-form-group span {
  color: #ff0000;
}

#editOrderModal .edit-order-body form .contact-form-group textarea {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  font-weight: normal;
}

#editOrderModal .edit-order-body form .contact-form-group input,
#editOrderModal .edit-order-body form .contact-form-group select {
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  padding: 0px 10px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  font-weight: normal;
}

#editOrderModal
  .edit-order-body
  form
  .contact-form-group
  .form-check-input-btn {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  padding: 0px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 5%;
  font-weight: normal;
  border-radius: 100px;
}

#editOrderModal .edit-order-body form .contact-form-button {
  border: 1px solid #26262c;
  outline: none;
  width: 100%;
  height: 45px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  background-color: #26262c;
  transition: all 0.3s ease;
}

#editOrderModal .edit-order-body form .contact-form-button:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.loading-data {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-data P {
  margin: 0px;
  font-size: 34px;
  font-weight: 700;
  color: #000000;
}

.error-show-para {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-show-para P {
  margin: 0px;
  font-size: 34px;
  font-weight: 700;
  color: #000000;
}

.btn-close:focus {
  box-shadow: none;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.form-select:focus {
  border-color: #dee2e6;
  outline: 0;
  box-shadow: none;
}

/* ========================= paid order ========================== */
.paid-order-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.paid-order-page .inner-container .paid-order-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paid-order-page .inner-container .paid-order-heading > .total-sale {
  font-size: 1.5rem;
  color: var(--secondary-color);
  text-align: end;
  margin: 0;
}

.paid-order-page .paid-order-page-heading {
  /* gap: 14px; */
}

.product-paid-orders-filter-block-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.product-paid-orders-filter-block-row > .start-date-block {
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 48px;
  border-radius: 4px;
  width: 25%;
}

.product-paid-orders-filter-block-row > .start-date-block .react-date-picker {
  height: 100%;
  width: 100%;
}

.product-paid-orders-filter-block-row
  > .start-date-block
  .react-date-picker
  .react-date-picker__wrapper {
  border: unset;
}

.product-paid-orders-filter-block-row > .end-date-block {
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 48px;
  border-radius: 4px;
  width: 25%;
}

.product-paid-orders-filter-block-row > .end-date-block .react-date-picker {
  height: 100%;
  width: 100%;
}

.product-paid-orders-filter-block-row
  > .end-date-block
  .react-date-picker
  .react-date-picker__wrapper {
  border: unset;
}

.paid-orders-main-block .paid-orders-body {
}

.paid-order-btn {
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0px;
  line-height: 0px;
  border: none;
  outline: none;
  background-color: unset;
}

.paid-order-svg-btn {
  margin-left: 18px;
}

/* .paid-order-page .paid-order-btn {
  border: 1px solid rgb(0, 0, 0);
  padding: 5px 20px;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  text-decoration: none;
  font-weight: 600;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 100%;
  line-height: 0px;
  transition: all 0.3s ease-in-out;
} */

/* .order-page .order-page-heading .order-page-btn:hover {
  color: #ffffff;
  background-color: #0b5ed7;
  border: 1px solid #ffffff;
} */

.paid-order-page .paid-order-page-body {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.paid-order-page .paid-order-page-body .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.paid-order-page .paid-order-page-body .table th,
.paid-order-page .paid-order-page-body .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.paid-order-page .paid-order-page-body .table th {
  background-color: #f2f2f2;
}

.paid-order-page .paid-order-page-body .table td .paid-order-btn {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
  border: none;
  outline: none;
  background-color: transparent;
}

.paid-order-page .paid-order-page-body .pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.paid-order-page .paid-order-page-body .pagination-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.paid-order-page .paid-order-page-body .pagination-buttons .pagination-btn {
  padding: 6px 20px;
  outline: none;
  height: 35px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #000000;
  color: #ffffff;
  background-color: #000000;
  transition: all 0.3s ease-in;
}

.paid-order-page
  .opaid-rder-page-body
  .pagination-buttons
  .pagination-btn:hover {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.paid-order-page
  .paid-order-page-body
  .pagination-buttons
  .pagination-btn:disabled {
  background-color: #dddddd;
  border: 1px solid #888888;
  color: #888888;
  cursor: not-allowed;
}

/* ========================= product-type ========================== */
.product-type-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.product-type-page .product-type-page-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-type-page .product-type-heading {
  gap: 14px;
}

.product-type-page .product-type-page-heading-add-link {
  border: 1px solid black;
  padding: 5px 20px;
  color: black;
  background: white;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  height: 38px;
}
.product-type-page .product-type-page-heading-add-link .plus-icon-add {
  font-size: 12px;
}

.product-type-page .product-type-page-body {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-type-page .product-type-page-body .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.product-type-page .product-type-page-body .table th,
.product-type-page .product-type-page-body .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.product-type-page .product-type-page-body .table th {
  background-color: #f2f2f2;
}

.product-type-page .product-type-page-body .table td img {
  max-width: 50px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.product-type-page .product-type-page-body .table td .action-icon-edit-link {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
}

.product-type-page .product-type-page-body .table td .action-icon-delete-btn {
  font-size: 16px;
  text-decoration: none;
  color: #e00000;
  border: 0px;
  outline: 0px;
  background: none;
  padding: 0px;
  line-height: 0px;
  margin-left: 10px;
}
/* =============== product type edit modal =============== */
.product-type-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-type-form-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.product-type-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-type-form-group
  label {
  font-size: 14px;
  margin-bottom: 4px;
  color: #000000;
  text-transform: capitalize;
  font-weight: 600;
}

.product-type-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-type-form-group
  input {
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  padding: 0px 10px;
  border-radius: 0px;
  border: 1px solid #d3d3d3;
  outline: none;
  width: 100%;
  font-weight: normal;
}

.product-type-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-type-form-group
  textarea {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 0px;
  border: 1px solid #d3d3d3;
  outline: none;
  width: 100%;
  font-weight: normal;
}

.product-type-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-type-form-button {
  border: 1px solid #ffffff;
  outline: none;
  width: 100%;
  height: 45px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  background-color: #26262c;
  transition: all 0.3s ease-in-out;
}

.product-type-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-type-form-button:hover {
  border: 1px solid #0f1d55;
  color: #ffffff;
  background-color: #0f1d55;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: none;
}

/* ========================= product-category ========================== */
.product-category-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.product-category-page .product-category-page-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-category-page .product-category-heading {
  gap: 14px;
}

.product-category-page .product-category-page-heading-add-link {
  border: 1px solid black;
  padding: 5px 20px;
  color: black;
  background: white;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  height: 38px;
}
.product-category-page .product-category-page-heading-add-link .plus-icon-add {
  font-size: 12px;
}

.product-category-page .product-category-page-body {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-category-page .product-category-page-body .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.product-category-page .product-category-page-body .table th,
.product-category-page .product-category-page-body .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.product-category-page .product-category-page-body .table th {
  background-color: #f2f2f2;
}

.product-category-page .product-category-page-body .table td img {
  max-width: 50px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.product-category-page
  .product-category-page-body
  .table
  td
  .action-icon-edit-link {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
}

.product-category-page
  .product-category-page-body
  .table
  td
  .action-icon-delete-btn {
  font-size: 16px;
  text-decoration: none;
  color: #e00000;
  border: 0px;
  outline: 0px;
  background: none;
  padding: 0px;
  line-height: 0px;
  margin-left: 10px;
}
/* =============== product type edit modal =============== */
.product-category-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-category-form-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.product-category-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-category-form-group
  label {
  font-size: 14px;
  margin-bottom: 4px;
  color: #000000;
  text-transform: capitalize;
  font-weight: 600;
}

.product-category-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-category-form-group
  input {
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  padding: 0px 10px;
  border-radius: 0px;
  border: 1px solid #d3d3d3;
  outline: none;
  width: 100%;
  font-weight: normal;
}

.product-category-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-category-form-group
  textarea {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 0px;
  border: 1px solid #d3d3d3;
  outline: none;
  width: 100%;
  font-weight: normal;
}

.product-category-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-category-form-button {
  border: 1px solid #ffffff;
  outline: none;
  width: 100%;
  height: 45px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  background-color: #26262c;
  transition: all 0.3s ease-in-out;
}

.product-category-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .product-category-form-button:hover {
  border: 1px solid #0f1d55;
  color: #ffffff;
  background-color: #0f1d55;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: none;
}

/* ========================= contact-us ========================== */
.contact-us-page .inner-container {
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
}

.contact-us-page .contact-us-page-heading {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-us-page .contact-us-heading {
  gap: 14px;
}

.contact-us-page .contact-us-page-heading form {
  max-width: 300px;
  width: 100%;
}

.contact-us-page .contact-us-page-heading .search-box-input input {
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  padding: 8px;
}

.contact-us-page .contact-us-page-heading-add-link {
  border: 1px solid black;
  padding: 5px 20px;
  color: black;
  background: white;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  height: 38px;
}
.contact-us-page .contact-us-page-heading-add-link .plus-icon-add {
  font-size: 12px;
}

.contact-us-page .contact-us-page-body {
  padding: 18px;
  border-radius: 14px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-us-page .contact-us-page-body .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.contact-us-page .contact-us-page-body .table th,
.contact-us-page .contact-us-page-body .table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.contact-us-page .contact-us-page-body .table th {
  background-color: #f2f2f2;
}

.contact-us-page .contact-us-page-body .table td img {
  max-width: 50px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.contact-us-page .contact-us-page-body .table td .action-icon-edit-link {
  font-size: 18px;
  text-decoration: none;
  color: #1c7900;
  padding: 0px;
  line-height: 0px;
}

.contact-us-page .contact-us-page-body .table td .action-icon-delete-btn {
  font-size: 16px;
  text-decoration: none;
  color: #e00000;
  border: 0px;
  outline: 0px;
  background: none;
  padding: 0px;
  line-height: 0px;
  margin-left: 10px;
}

.contact-us-page .contact-us-page-body .pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.contact-us-page .contact-us-page-body .pagination-controls label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.contact-us-page .contact-us-page-body .pagination-controls select {
  margin: 0px;
  padding: 4px;
}

.contact-us-page
  .contact-us-page-body
  .pagination-controls
  select:focus-visible {
  outline: none;
}

.contact-us-page .contact-us-page-body .pagination-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-us-page .contact-us-page-body .pagination-buttons .pagination-btn {
  padding: 6px 20px;
  outline: none;
  height: 35px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #000000;
  color: #ffffff;
  background-color: #000000;
  transition: all 0.3s ease-in;
}

.contact-us-page
  .contact-us-page-body
  .pagination-buttons
  .pagination-btn:hover {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
}

.contact-us-page
  .contact-us-page-body
  .pagination-buttons
  .pagination-btn:disabled {
  background-color: #dddddd;
  border: 1px solid #888888;
  color: #888888;
  cursor: not-allowed;
}
