:root {
  scroll-behavior: smooth;
  --primary-color: #cc0a67;
  --primary-hover-color: #e90070;
  --secondary-color: #29282b;
  --border-color: #16151638;
  --white-color: #ffffff;
  --black-color: #000000;
  --hover-color: #ffcee6;
  --para-light-color: #838383;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
}

.our-container {
  max-width: 1960px;
  width: 100%;
  margin: 0 auto;
}

img {
  object-fit: cover;
}

.react-date-picker__inputGroup__input:focus-visible {
  outline: 0px !important;
}

.react-date-picker__inputGroup__input:invalid {
  background-color: #ffffff !important;
}
