$primary-color: #000000;
$secondary-color: #444444;
$white-color: #ffffff;
$hover-bg-color: #000000;
$transition-effect: all 0.2s linear;

header {
  height: 100px;
  border-bottom: 1px solid #e2e2e2;
  position: static;
  width: 100%;
  z-index: 1000;
  transition: top 0.3s ease-in-out, position 0.3s ease-in-out;

  .header-top {
    background-color: $primary-color;
  }

  .upper-menu-block {
    background-color: $white-color;
    padding: 10px 0px;

    .inner-container {
      .logo-block {
        img {
          max-width: 130px;
          width: 100%;
        }
      }

      .mobile-info-menu {
        display: none;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .menu-icon-button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px !important;

          .menu-icon {
            font-size: 2rem;
          }

          &:active {
            background: none;
            border: none;
            outline: 0;
          }
        }

        .user-name-show-mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }

      .main-menu {
        background-color: $white-color;
        padding: 10px 0px;

        nav {
          .nav-links {
            gap: 30px;
            li {
              list-style: none;

              .dropdown {
                .myBTN {
                  &:hover {
                    background-color: transparent !important;
                    border-color: none !important;
                  }
                  &:active {
                    background-color: transparent !important;
                    border-color: none !important;
                  }
                  &:focus-visible {
                    background-color: transparent !important;
                    border-color: none !important;
                    outline: 0;
                    box-shadow: none !important;
                  }
                }
              }

              .nav-items {
                text-decoration: none;
                color: $secondary-color;
                transition: $transition-effect;

                &:hover {
                  color: $hover-bg-color;
                }

                &.active {
                  color: $primary-color !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

#offcanvasHeader {
  .mobile-header-offcanvas-header {
    border-bottom: 1px solid #4444442e;
  }

  .mobile-header-offcanvas-body {
    border-bottom: 1px solid #4444442e;

    .mobile-menu-nav {
      ul {
        padding: 0px;
        margin: 0px;

        li {
          list-style: none;
          height: 60px;
          display: flex;
          align-items: center;
          width: 100%;
          transition: $transition-effect;

          .nav-items {
            color: $secondary-color;
            text-decoration: none;
            width: 100%;
            transition: $transition-effect;

            &:hover {
              color: $hover-bg-color;
            }

            &.active {
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  .mobile-header-offcanvas-footer {
    width: 100%;
    background-color: whitesmoke;
    
    .mobile-header-logout-btn{
      padding: 10px 16px;
      width: 100%;
    }
  }
}

// @media screen and (max-device-width: 540px) {
//   header {
//     height: 170px;
//     // .upper-menu-block {
//     //   .inner-container {
//     //     flex-direction: column;
//     //   }
//     // }
//   }
// }

// // @media screen and (max-device-width: 1040px){
// //   header {
// //     height: 100px;
// //     border-bottom: 1px solid #e2e2e2;
// //     position: static;
// //     width: 100%;
// //     z-index: 1000;
// //     transition: top 0.3s ease-in-out, position 0.3s ease-in-out;

// //     .upper-menu-block {
// //       .inner-container {
// //         .main-menu {
// //          display: none;
// //         }
// //       }
// //     }
// //   }
// // }
